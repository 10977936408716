<template>
  <div class="detail-page">
    <Header />
    <Card class="card">
      <div class="label-list-container" v-for="(item, index) in bankList" :key="index">
        <div class="label-value-container">
          <div class="label">银行名称：</div>
          <div class="value">{{item.bankName}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">卡号：</div>
          <div class="value">{{item.cardCode}}</div>
        </div>
        <div class="label-value-container">
          <div class="label">开户人：</div>
          <div class="value">{{item.cardName}}</div>
        </div>
        <div style="font-size: 15px;color: red;text-align: left;">
          说明：通过此账号汇款，可多对一汇款，及时满足打款要求。
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from '@/components/common/card/whiteCard.vue'
import {getDealerBankById} from "@/api/home";
import {getDealerId} from "@/utils/user.js";


export default {
  components: {
    Header,
    Card,
  },
  data() {
    return {
      bankList: [],
    }
  },
  activated() {
    this.getFiList();
  },
  methods: {
    async getFiList() {
      const res = await getDealerBankById({
        id: getDealerId(),
      });
      this.bankList = res.data;
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  .card {
    margin-top: 10px;
    .label-list-container {
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .label-value-container {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      &:not(:last-child){
        margin-bottom: 6px;
      }
      .label {
        color: #818181;
      }
      .value {
        color: #2C2C2C;
        margin-left: 6px;
      }
    }
  }

}
</style>

